<template>
  <div
    v-if="hotspot.type"
    :class="`hotspot ${`hotspot__${hotspot.type.value}`} ${
      clickCount > 0 && isTouchDevice ? 'touched' : ''
    }`"
    :id="hotspot.id"
    :data-title="hotspot.linkedPano ? hotspot.linkedPano.title : ''"
    :data-slug="hotspot.linkedPano ? hotspot.linkedPano.slug : ''"
    :data-name="hotspot.linkedPano ? hotspot.linkedPano.name : ''"
    :data-ath="hotspot.ath"
    :data-atv="hotspot.atv"
    :data-type="hotspot.type.value"
    v-click-outside="handleClickOutside"
  >
    <div :class="`hotspot__inner hotspot__inner__${hotspot.type.value}`">
      <img
        :src="`/assets/img/icons/icon-${hotspot.type.value}.svg`"
        alt="hotspot icon"
      />
    </div>

    <div
      :class="`hotspot__content ${`hotspot__content--${hotspot.type.value}`}`"
    >
      <span v-if="hotspot.type.value == 'navigation'">
        {{
          hotspot.linkedPano.title
            ? hotspot.linkedPano.title
            : $t.uiContinueLabel
        }}
      </span>
      <span
        v-else-if="
          hotspot.type.value == 'info' || hotspot.type.value == 'small-info'
        "
      >
        {{ hotspot.modal.title }}
      </span>
      <p
        v-if="hotspot.type.value == 'small-info' && hotspot.modal.smallContent"
        v-html="hotspot.modal.smallContent.substring(0, 250)"
      ></p>
      <div
        v-if="hotspot.type.value !== 'small-info'"
        class="hotspot__content__bg"
      ></div>
    </div>
  </div>
</template>

<script>
import { isTouchDevice, addSingleVrHotspot } from "@/helpers";
import vClickOutside from "v-click-outside";

export default {
  name: "Hotspot",
  props: {
    webVRActive: Boolean,
    hotspot: Object,
    clickedOutside: Boolean,
  },
  data() {
    return {
      tour: this.$store.state.currentTour,
      pano: this.$store.state.currentPano,
      isTouchDevice: isTouchDevice(),
      clickCount: 0,
    };
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  mounted() {
    let hotspotDOM = document.getElementById(this.hotspot.id);
    if (this.webVRActive && hotspotDOM) {
      addSingleVrHotspot(hotspotDOM, this.$t.uiContinueLabel);
    }
  },
  methods: {
    handleClickOutside(event) {
      this.clickCount = 0;
      if (
        document.getElementById(this.hotspot.id) &&
        document
          .getElementById(this.hotspot.id)
          .classList.contains("hotspot__small-info")
      ) {
        document.getElementById(this.hotspot.id).classList.remove("touched");
      }
    },
    updateClasses() {
      document.querySelectorAll(".hotspot__small-info").forEach((el) => {
        el.classList.remove("touched");
      });
      document.querySelectorAll(".locator__touched").forEach((el) => {
        el.classList.remove("locator__touched");
      });
      document
        .getElementById(`hotspot__locator--${this.hotspot.id}`)
        .classList.add("locator__touched");
    },
    handleClick() {
      this.updateClasses();
      this.clickCount++;

      if (
        this.isTouchDevice &&
        this.clickCount < 2 &&
        this.hotspot.type.value !== "small-info"
      )
        return;

      let data = {
        hotspot_type: this.hotspot.type.value,
      };

      switch (this.hotspot.type.value) {
        case "navigation":
          this.$emit("navigate", this.hotspot.linkedPano);
          data.nav_from = this.pano.title;
          data.nav_to = this.hotspot.linkedPano.title;
          break;
        case "info":
          this.$emit("info", this.hotspot.modal);
          data.modal_on = this.pano.title;
          data.modal_title = this.hotspot.modal.title;
          data.modal_type = this.hotspot.modal.hasVideo
            ? "Video modal"
            : this.hotspot.modal.images && this.hotspot.modal.images.length > 0
            ? "Image modal"
            : "Text modal";
          break;
        case "small-info":
          const smallInfo = document.getElementById(this.hotspot.id);
          if (!smallInfo.classList.contains("touched")) {
            document.getElementById(this.hotspot.id).classList.add("touched");
          }
          break;
      }

      // Fire GTM event for click
      if (data) {
        this.sendGtmEvent(
          "click-hotspot",
          data,
          this.tour.title,
          this.pano.title
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../scss/components/hotspot/hotspot.scss";
</style>
