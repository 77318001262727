<template>
  <p>
    <transition name="readmore-text-fade" mode="out-in">
      <span :key="visibleText">
        {{ visibleText }}
        <a
          v-if="text.length > max && maxCharVisible < text.length"
          href="#!"
          @click.prevent="maxCharVisible = text.length"
        >
          {{ $t.uiReadMore }}
        </a>
        <a
          v-else-if="text.length > max"
          href="#!"
          @click.prevent="maxCharVisible = max"
        >
          {{ $t.uiReadLess }}
        </a>
      </span>
    </transition>
  </p>
</template>

<script>
export default {
  props: {
    open: { default: false },
    max: { default: 250 },
    text: { default: "" },
  },
  data() {
    return {
      maxCharVisible: this.max,
    };
  },
  computed: {
    visibleText() {
      if (this.maxCharVisible < this.text.length) {
        return `${this.text.substring(0, this.maxCharVisible)} ...`;
      } else {
        return `${this.text.substring(0, this.maxCharVisible)}`;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
span {
  display: inline-block;

  a {
    display: inline;
    padding: 0 1.2rem;
    transition: font-weight $basic-trans-100;

    &:hover {
      font-weight: bold;
    }

    @include small-up {
      padding: 0 rs(12);
    }
  }
}
</style>
