<template>
  <div class="tutorial tutorial__overlay" @click="$emit('close')">
    <h2>{{ $t.tutorialTitle }}</h2>

    <div class="tutorial__step tutorial__step-1">
      <!-- Image -->
      <!-- <img
        v-if="isTouchDevice()"
        src="/assets/img/tutorial/touch-pan--mobile.png"
        alt="Pan image mobile"
      />
      <img v-else src="/assets/img/tutorial/touch-pan.png" alt="Pan image" /> -->
      <!-- Text -->
      <p v-if="isTouchDevice()">{{ $t.tutorialDragMobile }}</p>
      <p v-else>{{ $t.tutorialDrag }}</p>
    </div>

    <div class="tutorial__step tutorial__step-2">
      <!-- Image -->
      <!-- <img
        v-if="isTouchDevice()"
        src="/assets/img/tutorial/touch-pan--mobile.png"
        alt="Pan image mobile"
      />
      <img v-else src="/assets/img/tutorial/touch-pan.png" alt="Pan image" /> -->
      <!-- Text -->
      <p v-if="isTouchDevice()">{{ $t.tutorialZoomMobile }}</p>
      <p v-else>{{ $t.tutorialZoom }}</p>
    </div>

    <a
      id="tutorial__button"
      class="button button--outline button--outline-white"
      @click.prevent="$emit('close')"
    >
      {{ $t.tutorialButton }}
    </a>
  </div>
</template>

<script>
import { isTouchDevice } from "@/helpers";

export default {
  name: "TutorialScreen",
  methods: {
    isTouchDevice: isTouchDevice,
  },
  computed: {
    tour() {
      return this.$store.state.currentTour;
    },
  },
  mounted() {
    localStorage.setItem("tutorialShown", true);
  },
};
</script>

<style lang="scss" scoped>
.tutorial {
  z-index: 10000;

  &__overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1001;
    background-color: transparent;
    transition: opacity $basic-trans-200;
    background-color: rgba($color-black, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    cursor: pointer;
  }

  * {
    color: $color-white;
  }

  h2 {
    color: var(--primary-color);
    font-weight: bold;
    text-transform: uppercase;
  }

  .tutorial__step {
    display: flex;
    align-items: center;
    text-align: center;
    flex-direction: column;
    grid-gap: 2rem;

    img {
      margin-right: 1.2rem;
    }

    &-1 {
      margin: 5.6rem 0 1.2rem 0;
    }

    &-2 {
      margin: 1.2rem 0 5.6rem 0;
    }
  }

  a {
    cursor: pointer;
    padding: 1rem 2rem;
    font-weight: bold;
    border: 0.2rem solid $color-white;
    border-radius: 0.4rem;
    transition: all $basic-trans-200;

    &:hover {
      color: $color-black;
      background: $color-white;
    }
  }

  @include small-up {
    .tutorial__step {
      grid-gap: rs(20);

      img {
        margin-right: rs(12);
      }

      &-1 {
        margin: rs(56) 0 rs(12) 0;
      }

      &-2 {
        margin: rs(12) 0 rs(56) 0;
      }
    }

    a {
      padding: rs(10) rs(20);
      border: rs(2) solid $color-white;
      border-radius: rs(4);
    }
  }
}
</style>
