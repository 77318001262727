<template>
  <div
    v-if="tour"
    :class="`gallery__container ${open ? 'gallery__container_open' : ''}`"
  >
    <div class="gallery__inner" @click.self.prevent="$emit('close')">
      <Languages class="languages gui__element gui__element--top-left" />
      <a
        class="btn--menu gallery--close gui__element gui__element--top-right"
        href="#!"
        @click.prevent="$emit('close')"
        tabindex="-1"
      >
        <img :src="`/assets/img/icons/icon-close.svg`"
      /></a>

      <div class="gallery__wrapper">
        <Flicking
          :options="flickingOptions"
          id="gallery"
          class="gallery__grid gallery"
          ref="flick"
          v-if="horizontal"
        >
          <div
            :class="`gallery__card flicking-panel ${
              active.slug == pano.slug ? 'active' : ''
            }`"
            v-for="pano in tour.panos"
            :key="pano.slug"
            @click="handleClick(pano)"
          >
            <span>{{ pano.title }}</span>
            <img
              v-if="pano.thumbnailUrl"
              :src="
                pano.thumbnailUrl.includes('http')
                  ? pano.thumbnailUrl
                  : `/assets/vtours/${tour.id}/${pano.thumbnailUrl}`
              "
              :alt="`Thumbnail for pano ${pano.slug}`"
            />
            <img
              v-else
              :src="`/assets/vtours/${tour.id}/panos/${pano.name}.tiles/thumb.jpg`"
              :alt="`Thumbnail for pano ${pano.slug}`"
            />
            <!-- <div v-else class="placeholder">
              <img src="/assets/img/logo.svg" alt="project logo" />
            </div> -->
            <div class="border"></div>
          </div>
        </Flicking>
        <div class="flicking__controls" v-if="horizontal">
          <a
            tabindex="-1"
            href="#!"
            class="flicking__controls__control flicking__controls--prev"
            @click.prevent="prev"
            ><img src="/assets/img/icons/icon-arrow.svg" alt="Gallery arrow"
          /></a>
          <a
            tabindex="-1"
            href="#!"
            class="flicking__controls__control flicking__controls--next"
            @click.prevent="next"
            ><img src="/assets/img/icons/icon-arrow.svg" alt="Gallery arrow"
          /></a>
        </div>

        <div v-if="!horizontal" class="gallery__grid" id="gallery">
          <div
            :class="`gallery__card ${active.slug == pano.slug ? 'active' : ''}`"
            v-for="pano in tour.panos"
            :key="pano.slug"
            @click="handleClick(pano)"
          >
            <span>{{ pano.title }}</span>
            <img
              v-if="pano.thumbnailUrl"
              :src="
                pano.thumbnailUrl.includes('http')
                  ? pano.thumbnailUrl
                  : `/assets/vtours/${tour.id}/${pano.thumbnailUrl}`
              "
              :alt="`Thumbnail for pano ${pano.slug}`"
            />
            <div v-else class="placeholder">
              <img src="/assets/img/logo.svg" alt="project logo" />
            </div>
            <div class="border"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="gallery__outer" @click="$emit('close')"></div>
  </div>
</template>

<script>
import Languages from "@/components/Languages.vue";
import { Flicking } from "@egjs/vue-flicking";
import "@egjs/vue-flicking/dist/flicking.css";
import "@egjs/vue-flicking/dist/flicking-inline.css";

export default {
  name: "Gallery",
  components: {
    Flicking: Flicking,
    Languages,
  },
  props: {
    open: Boolean,
    close: Function,
  },
  data() {
    return {
      flickingOptions: {
        // renderOnlyVisible: true,
        align: "center",
        defaultIndex: 0,
        bounce: 0,
        // preventClickOnDrag: true,
      },
      horizontal: window.innerWidth > 900,
      flickingIndex: 0,
      activeIndex: 0,
    };
  },
  computed: {
    tour() {
      return this.$store.state.currentTour;
    },
    active() {
      return this.$store.state.currentPano;
    },
  },
  mounted() {
    window.addEventListener(
      "resize",
      () => (this.horizontal = window.innerWidth > 900)
    );
  },
  methods: {
    handleClick(pano) {
      if (this.active.slug != pano.slug) {
        this.$loadPano(pano);
      }
      this.$emit("close");
    },
    async next() {
      await this.$refs.flick.next(0).catch(() => void 0);
    },
    async prev() {
      await this.$refs.flick.prev(0).catch(() => void 0);
    },
  },
};
</script>

<style lang="scss" scoped>
.gallery {
  &__container {
    background-color: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transition: all 0.25s ease-out;
    visibility: hidden;

    &_open {
      .gallery__inner {
        bottom: 0;
        top: 0;
      }

      .gallery__outer {
        pointer-events: all;
        opacity: 0.3;
      }
    }
  }

  &__grid {
    overflow: auto;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding: 5.6rem;

    @include small-up {
      overflow: hidden;
      height: rs(250);
      margin: rs(56);
      padding: 0;
      align-items: center;
      flex-direction: column;
      justify-content: center;
    }
  }

  &__wrapper {
    height: 100%;
    width: 100%;
    display: flex;

    @include small-up {
      height: auto;
      align-items: center;
      justify-content: center;
      position: relative;
    }
  }

  &__card {
    min-height: 15rem;
    max-height: 25rem;
    width: 100%;
    overflow: hidden;
    border-radius: 0.4rem;
    cursor: pointer;
    position: relative;
    transition: all $basic-trans-100;
    margin: 1.2rem auto;

    @include small-up {
      max-height: rs(250);
      width: rs(250);
      margin: rs(12);
      border-radius: rs(4);
    }

    span {
      position: absolute;
      bottom: 0.5rem;
      left: 1rem;
      z-index: 2;
      opacity: 1;
      font-size: 1.6rem;
      margin-bottom: 0.8rem;
      font-weight: bold;
      text-transform: uppercase;
      color: $color-text-inverse;
      transition: all $basic-trans-200;

      @include small-up {
        bottom: rs(5);
        left: rs(10);
        font-size: rs(16);
        margin-bottom: rs(8);
      }
    }

    img,
    .placeholder {
      object-fit: cover;
      height: 100%;
      width: 100%;
      transform: scale(1);
      transition: all $basic-trans-200;
      filter: grayscale(100%) brightness(50%);
      pointer-events: none;
    }

    .placeholder {
      background: rgba($color-white, 0.9);
    }

    .border {
      position: absolute;
      bottom: 0;
      overflow: hidden;
      width: 100%;
      max-height: 0;
      background-color: var(--primary-color);
      transition: max-height $basic-trans-200;
      height: 0.5rem;

      @include small-up {
        height: rs(5);
      }
    }

    &:hover,
    &.active {
      img {
        transform: scale(1.1);
        filter: grayscale(0%);
      }

      .border {
        max-height: 0.5rem;

        @include small-up {
          max-height: rs(5);
        }
      }
    }
  }

  &--close {
    position: absolute;
    z-index: 1;
    display: block;
    width: 2.8rem;
    height: 2.8rem;
    background: transparent;

    img {
      width: 2.8rem;
      height: 2.8rem;
      transition: transform $basic-trans-300;

      filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(50deg)
        brightness(110%) contrast(100%);
    }

    &:hover img {
      transform: rotate(90deg);
    }

    &:active {
      transform: scale(1.05);
    }

    @include small-up {
      width: rs(28);
      height: rs(28);

      img {
        width: rs(28);
        height: rs(28);
      }
    }
  }

  &__inner {
    background: rgba($color-black, 0.9);
    position: absolute;
    bottom: -100%;
    top: 100%;
    left: 0;
    right: 0;
    transition: bottom $basic-trans-500 0.1s, top $basic-trans-500 0.1s;
    visibility: visible;
    z-index: 1003;
    pointer-events: all;
    max-height: 100%;
    overflow: hidden;
    scroll-behavior: smooth;
    margin: 0 auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-end;
    width: 100%;
  }

  &__outer {
    cursor: pointer;
    visibility: visible;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: $color-white;
    opacity: 0;
    z-index: 999;
    transition: opacity $basic-trans-500 0.1s;
    pointer-events: none;
  }
}

.flicking-camera {
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.flicking__controls {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  pointer-events: none;

  &__control {
    text-decoration: none;
    z-index: 1;
    left: 50%;
    display: grid;
    place-content: center;
    height: 5rem;
    width: 5rem;
    position: absolute;
    transition: all $basic-trans-200;
    pointer-events: all;

    img {
      filter: invert(92%) sepia(100%) saturate(0%) hue-rotate(202deg)
        brightness(106%) contrast(106%);
      height: 100%;
      width: 100%;
      position: relative;
    }

    @include small-up {
      top: 50%;
      height: rs(50);
      width: rs(50);
    }
  }

  &--prev {
    top: $ui-padding-mobile;
    transform: translateX(-50%) rotate(-90deg);

    &:hover {
      opacity: 0.5;
      transform: translateX(-50%) rotate(-90deg) scale(1.3);
    }
  }

  &--next {
    bottom: $ui-padding-mobile;
    transform: translateX(-50%) rotate(90deg);

    &:hover {
      opacity: 0.5;
      transform: translateX(-50%) scale(1.3) rotate(90deg);
    }
  }

  @include small-up {
    &--prev {
      left: $ui-padding;
      top: 50%;
      transform: translateY(-50%) rotate(180deg);

      &:hover {
        opacity: 0.5;
        transform: translateY(-50%) rotate(180deg) scale(1.3);
      }
    }

    &--next {
      right: $ui-padding;
      left: unset;
      transform: translateY(-50%);

      &:hover {
        opacity: 0.5;
        transform: translateY(-50%) scale(1.3);
      }
    }
  }
}
</style>
