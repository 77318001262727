<template>
  <div class="dot__row" v-if="tour">
    <transition name="dots-title-fade" mode="out-in">
      <p v-if="!title" class="dot__title" key="invisible"></p>
      <p v-else class="dot__title" key="visible">{{ title }}</p>
    </transition>
    <div
      v-for="pano in tour.panos"
      :key="pano.slug"
      class="dot__container"
      @click="handleClick(pano)"
      @mouseover="changeTitle(pano.title)"
      @mouseout="changeTitle(null)"
    >
      <div :class="`dot ${active.slug == pano.slug ? 'dot--active' : ''}`">
        <div class="dot__background"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Dots",
  data() {
    return {
      title: null,
    };
  },
  computed: {
    tour() {
      return this.$store.state.currentTour;
    },
    active() {
      return this.$store.state.currentPano;
    },
  },
  methods: {
    handleClick(pano) {
      if (this.active.slug != pano.slug) {
        this.$loadPano(pano);
      }
      this.$emit("close");
    },
    changeTitle(newTitle) {
      if (newTitle) {
        this.title = newTitle;
      } else {
        this.title = null;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.dot {
  display: block;
  width: 1.2rem;
  height: 1.2rem;
  background-color: $color-white;
  border-radius: 50%;
  position: relative;
  display: grid;
  place-content: center;
  pointer-events: none;

  &--active {
    width: 2.4rem;
    height: 2.4rem;
  }

  &__background {
    width: 0.8rem;
    height: 0.8rem;
    background-color: var(--primary-color);
    border-radius: 50%;
    z-index: -1;
    transition: all $basic-trans-200;
  }

  &__container {
    margin: 0 1.2rem;
    cursor: pointer;
    width: 3.2rem;
    height: 3.2rem;
    display: grid;
    place-content: center;
    pointer-events: all;

    &:hover .dot:not(.dot--active) .dot__background {
      width: 3.2rem;
      height: 3.2rem;
    }
  }

  &__row {
    display: none;
    max-width: 50%;
    min-width: 50rem;
  }

  &__title {
    color: $color-text-inverse;
    font-weight: bold;
    top: -3.2rem;
    position: absolute;
  }

  @include small-up {
    width: rs(12);
    height: rs(12);

    &--active {
      width: rs(24);
      height: rs(24);
    }

    &__background {
      width: rs(8);
      height: rs(8);
    }

    &__container {
      margin: 0 rs(12);
      width: rs(32);
      height: rs(32);

      &:hover .dot:not(.dot--active) .dot__background {
        width: rs(32);
        height: rs(32);
      }
    }

    &__row {
      min-width: rs(500);
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }

    &__title {
      top: rs(-32);
    }
  }
}
</style>
