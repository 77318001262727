import axios from "axios";

axios.defaults.baseURL = `${window.location.origin}${process.env.VUE_APP_API_URL}`;
axios.defaults.params = {
  locale: localStorage.getItem("locale"),
};

const virtualTourApi = {
  getAvailableLocales: async (vtourId, panoId) => {
    return await axios.get(
      `/locales?${vtourId ? `vtourId=${vtourId}&` : ""}${
        panoId ? `panoId=${panoId}` : ""
      }`
    );
  },
  getAllTours: async () => {
    return await axios.get(`/all`);
  },
  getPanosForTour: async (slug = null) => {
    return await axios.get(slug ? `?slug=${slug}` : "/");
  },
  getPano: async (vtourId, name) => {
    return await axios.get(`/pano?vtourId=${vtourId}&name=${name}`);
  },
  getHotspot: async (vtourId, panoName, hotspotName) => {
    return await axios.get(
      `/pano/hotspot?vtourId=${vtourId}&panoName=${panoName}&hotspotName=${hotspotName}`
    );
  },
  getCategories: async () => {
    return await axios.get(`/categories`);
  },
};

export default virtualTourApi;
