<template>
  <div :class="`modal__container ${open ? 'modal__container_open' : ''}`">
    <div class="modal__inner">
      <a
        class="btn--menu modal--close gui__element gui__element--top-right"
        href="#!"
        @click.prevent="$emit('close')"
      >
        <img :src="`/assets/img/icons/icon-close.svg`"
      /></a>

      <div class="modal__content">
        <h2 class="modal__content__title" v-if="content.title">
          {{ content.title }}
        </h2>
        <p
          class="modal__content__text"
          v-if="content.content"
          v-html="content.content"
        ></p>
        <Flicking
          v-if="content.images"
          :options="flickingOptions"
          ref="flick"
          class="modal__content__gallery"
        >
          <div
            v-for="(image, index) in content.images"
            :key="image"
            class="img__wrapper"
            @click="tinyIndex = index"
          >
            <img :src="image" alt="Modal image" />
          </div>
        </Flicking>
        <div
          v-if="
            open &&
            content.hasVideo &&
            content.videoUrl &&
            content.videoType.value
          "
        >
          <iframe
            v-if="content.videoType.value.toLowerCase() == 'youtube'"
            :src="`https://www.youtube.com/embed/${parseYoutubeLink(
              content.videoUrl
            )}`"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
          <iframe
            v-if="content.videoType.value.toLowerCase() == 'vimeo'"
            title="vimeo-player"
            :src="`https://player.vimeo.com/video/${parseVimeoLink(
              content.videoUrl
            )}`"
            frameborder="0"
            allowfullscreen
          ></iframe>
        </div>
        <div class="modal__content__actions">
          <a
            v-if="content.attachedFileLink"
            :href="content.attachedFileLink"
            download
          >
            {{ $t.uiDownloadFile }}
            <img
              :src="`/assets/img/icons/icon-navigation.svg`"
              alt="arrow icon"
            />
          </a>
          <a
            v-if="content.externalLink"
            :href="content.externalLink"
            rel="noopener noreferrer"
            target="_blank"
            >{{
              content.externalLinkTitle
                ? content.externalLinkTitle
                : "Open external link"
            }}
            <img
              :src="`/assets/img/icons/icon-navigation.svg`"
              alt="arrow icon"
          /></a>
        </div>
      </div>
    </div>
    <Tinybox
      v-if="content.images"
      v-model="tinyIndex"
      :images="content.images"
      no-thumbs
      loop
    />
    <div class="modal__outer" @click="$emit('close')"></div>
  </div>
</template>

<script>
import { parseYoutubeLink, parseVimeoLink } from "@/helpers";
import { Flicking } from "@egjs/vue-flicking";
import "@egjs/vue-flicking/dist/flicking.css";
import "@egjs/vue-flicking/dist/flicking-inline.css";
import Tinybox from "vue-tinybox";

export default {
  name: "Modal",
  components: {
    Flicking: Flicking,
    Tinybox,
  },
  props: {
    content: Object,
    open: Boolean,
    close: Function,
  },
  data() {
    return {
      tinyIndex: null,
      flickingOptions: {
        align: "prev",
        bounce: 0,
      },
    };
  },
  methods: {
    parseYoutubeLink: parseYoutubeLink,
    parseVimeoLink: parseVimeoLink,
  },
};
</script>

<style lang="scss" scoped>
.modal {
  &__container {
    display: grid;
    position: absolute;
    background: rgba($color-black, 0.7);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1001;
    opacity: 0;
    visibility: hidden;
    transition: opacity $basic-trans-500 0.1s;

    &_open {
      pointer-events: all;
      opacity: 1;
      visibility: visible;

      .modal__inner {
        opacity: 1;
        top: unset;
        bottom: 0;
        transform: translateX(-50%);
        visibility: visible;

        width: 100%;
        height: auto;
        max-height: 75%;

        .modal__content {
          opacity: 1;
        }

        @include small-up {
          width: 70%;
          height: auto;
          max-height: 80%;
          max-width: rs(800);
          border-radius: rs(4);
          bottom: unset;
          top: 50%;
          transform: translate(-50%, -50%);
        }
      }

      .modal__outer {
        pointer-events: all;
        opacity: 0.3;
      }
    }
  }

  &__inner {
    background-color: $color-white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    z-index: 1001;
    position: absolute;
    opacity: 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 100%);
    transition: height 0.3s linear 0.1s, width 0.3s linear 0.1s,
      opacity $basic-trans-500 0.1s, transform $basic-trans-500 0.1s;
    line-height: 1.4;
    border-radius: 0.4rem 0.4rem 0 0;
    font-size: 1.6rem;

    .modal__content {
      width: 100%;
      opacity: 0;
      transition: opacity $basic-trans-300 0.5s;
    }

    @include small-up {
      width: 5rem;
      height: 5rem;
      border-radius: rs(4) rs(4) 0 0;
      width: rs(20);
      height: rs(20);
      font-size: rs(16);
      transform: translate(-50%, -70%);
    }
  }

  &__outer {
    pointer-events: all;
    cursor: pointer;
    opacity: 0;
  }

  &--close {
    position: absolute;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3.6rem;
    height: 3.6rem;
    background: transparent;

    img {
      width: 2.8rem;
      height: 2.8rem;
      transition: transform $basic-trans-300;
      filter: invert(6%) sepia(3%) saturate(179%) hue-rotate(314deg)
        brightness(96%) contrast(85%);
    }

    &:hover img {
      transform: rotate(90deg);
    }

    &:active {
      transform: scale(1.05);
    }

    @include small-up {
      width: rs(36);
      height: rs(36);

      img {
        width: rs(28);
        height: rs(28);
      }
    }
  }

  &__content {
    padding: 2.4rem;
    overflow: auto;
    text-align: left;

    &__title {
      margin: 0 0 2.4rem 0;
    }

    &__gallery {
      overflow: hidden;
      cursor: grab;
      overflow: hidden;

      .img__wrapper {
        width: 25rem;
        min-height: 15rem;
        max-height: 25rem;
        margin: 2.4rem 1.6rem;
        overflow: hidden;
        border-radius: 0.4rem;

        img {
          object-fit: cover;
          height: 100%;
          width: 100%;
          pointer-events: none;
          transition: all $basic-trans-200;
        }

        &:hover {
          cursor: pointer;

          img {
            transform: scale(1.1);
          }
        }
      }

      :active {
        cursor: grabbing;
      }
    }

    iframe {
      width: 100%;
      min-height: 30rem;
      margin-top: 2.4rem;
    }

    &__actions {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;

      a {
        margin-top: 2.4rem;
        color: var(--primary-color-darker);
        transition: color $basic-trans-200;
        display: block;

        img {
          height: 2.4rem;
          vertical-align: middle;
          margin-left: 0;
          transition: margin $basic-trans-200;
        }

        &:hover {
          color: $color-text;

          img {
            margin-left: 0.4rem;
          }
        }
      }
    }

    @include small-up {
      padding: rs(24);

      &__title {
        margin: 0 0 rs(24) 0;
      }

      &__gallery .img__wrapper {
        width: rs(250);
        min-height: rs(150);
        max-height: rs(250);
        margin: rs(24) rs(16);
        border-radius: rs(4);
      }

      iframe {
        min-height: rs(300);
        margin-top: rs(24);
      }

      &__actions {
        flex-direction: row;
        justify-content: space-between;

        a {
          margin-top: rs(24);

          img {
            height: rs(24);
          }

          &:hover {
            img {
              margin-left: rs(4);
            }
          }
        }
      }
    }
  }
}

.flicking-camera {
  display: inline-flex;
}
</style>
