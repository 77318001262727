import {
  addExitVrHotspot,
  addSingleVrHotspot,
  addVrHotspots,
  removeVrHotspots,
} from "./vr";

const parseYoutubeLink = (url) => {
  let regExp =
    /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  let match = url.match(regExp);
  return match && match[7].length == 11 ? match[7] : false;
};

const parseVimeoLink = (url) => {
  let regExp =
    /^.*(vimeo\.com\/)((channels\/[A-z]+\/)|(groups\/[A-z]+\/videos\/))?([0-9]+)/;
  let match = url.match(regExp);
  return match[5];
};

const isTouchDevice = () => {
  return "ontouchstart" in document.documentElement && window.innerWidth <= 900;
};

const getSubdomain = (baseUrl) => {
  const firstBaseSegment = new URL(baseUrl).host.split(".")[0];
  const host = window.location.host.toLowerCase();
  const segments = host.split(".");
  if (
    segments.length >= 3 &&
    segments[0] !== "www" &&
    segments[0] !== firstBaseSegment
  ) {
    // Has subdomain
    const subdomain = segments[0];
    return subdomain;
  }
  // No subdomain
  return null;
};

export {
  addExitVrHotspot,
  addSingleVrHotspot,
  addVrHotspots,
  removeVrHotspots,
  parseYoutubeLink,
  parseVimeoLink,
  isTouchDevice,
  getSubdomain,
};
