<template>
  <div class="splash">
    <div class="splash__logo">
      <img :src="$l.logo" alt="project logo" />
    </div>
    <p>
      {{ $t.uiLoading }}
    </p>
    <div class="poppr">
      <a href="https://poppr.be/" rel="noopener noreferrer" target="_blank">
        {{ $t.uiPoweredBy }}
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "SplashScreen",
};
</script>

<style lang="scss" scoped>
.splash {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10001;
  background-color: var(--primary-color);
  display: grid;
  place-content: center;
  text-align: center;

  &__logo {
    height: 20rem;
    width: 20rem;

    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }

  p {
    margin: 5.6rem auto;
    font-weight: bold;
    color: $color-text-inverse;
  }

  .poppr a {
    position: absolute;
    bottom: 2.4rem;
    left: 50%;
    transform: translateX(-50%);
    color: $color-text-inverse;
    text-decoration: none;
    opacity: 0.8;
    font-size: 1.2rem;

    &:hover {
      text-decoration: underline;
    }
  }

  @include small-up {
    &__logo {
      height: rs(200);
      width: rs(200);
    }

    p {
      margin: rs(56) auto;
    }

    .poppr a {
      bottom: rs(24);
      font-size: rs(12);
    }
  }
}

@keyframes fade-in-out {
  0% {
    opacity: 0.3;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0.3;
  }
}
</style>
