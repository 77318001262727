import Vue from "vue";
import VueRouter from "vue-router";
import * as views from "../views";
import { getSubdomain } from "../helpers";
import virtualTourApi from "../api";

// Set init locale
if (localStorage.getItem("locale")) {
  Vue.prototype.$locale = localStorage.getItem("locale");
} else {
  Vue.prototype.$locale = process.env.VUE_APP_FALLBACK_LOCALE;
}
localStorage.setItem("locale", Vue.prototype.$locale);

Vue.use(VueRouter);

const routes = [
  // Multi tour
  // {
  //   path: "/:locale?/virtual-tour",
  //   name: "Home - Virtual tour",
  //   component: views.Home,
  // },
  // {
  //   path: "/:locale?/virtual-tour/:tour/:panorama?",
  //   name: "Tour - Virtual tour",
  //   component: views.Krpano,
  // },

  // Single tour
  {
    path: "/:locale/:panorama?",
    name: "Tour - Virtual tour",
    component: views.Krpano,
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

router.beforeEach((to, from, next) => {
  checkLanguage(to, from, next);
  checkSubdomain(to, from, next);
});

function checkLanguage(to, from, next) {
  if (to.params.locale) {
    Vue.prototype.$locale = to.params.locale;
    localStorage.setItem("locale", to.params.locale);
  } else {
    let path = `/${
      localStorage.getItem("locale")
        ? localStorage.getItem("locale")
        : process.env.VUE_APP_FALLBACK_LOCALE
    }`;
    window.location.href = path;
  }
}

function checkSubdomain(to, from, next) {
  const subdomain = getSubdomain(process.env.VUE_APP_BASE_URL);
  if (subdomain) {
    if (!to.params.panorama) {
      // Get first pano of tour and navigate
      virtualTourApi.getFirstPanoForTour(subdomain).then((res) => {
        const target = `/${localStorage.getItem("locale")}/${res.data}`;
        if (to.fullPath !== target) {
          next(target);
        }
      });
    }
  }
  next();
}

window.popStateDetected = false;
window.addEventListener("popstate", () => {
  window.popStateDetected = true;
});

export default router;
