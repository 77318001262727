var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:("modal__container " + (_vm.open ? 'modal__container_open' : ''))},[_c('div',{staticClass:"modal__inner"},[_c('a',{staticClass:"btn--menu modal--close gui__element gui__element--top-right",attrs:{"href":"#!"},on:{"click":function($event){$event.preventDefault();return _vm.$emit('close')}}},[_c('img',{attrs:{"src":"/assets/img/icons/icon-close.svg"}})]),_c('div',{staticClass:"modal__content"},[(_vm.content.title)?_c('h2',{staticClass:"modal__content__title"},[_vm._v(" "+_vm._s(_vm.content.title)+" ")]):_vm._e(),(_vm.content.content)?_c('p',{staticClass:"modal__content__text",domProps:{"innerHTML":_vm._s(_vm.content.content)}}):_vm._e(),(_vm.content.images)?_c('Flicking',{ref:"flick",staticClass:"modal__content__gallery",attrs:{"options":_vm.flickingOptions}},_vm._l((_vm.content.images),function(image,index){return _c('div',{key:image,staticClass:"img__wrapper",on:{"click":function($event){_vm.tinyIndex = index}}},[_c('img',{attrs:{"src":image,"alt":"Modal image"}})])}),0):_vm._e(),(
          _vm.open &&
          _vm.content.hasVideo &&
          _vm.content.videoUrl &&
          _vm.content.videoType.value
        )?_c('div',[(_vm.content.videoType.value.toLowerCase() == 'youtube')?_c('iframe',{attrs:{"src":("https://www.youtube.com/embed/" + (_vm.parseYoutubeLink(
            _vm.content.videoUrl
          ))),"title":"YouTube video player","frameborder":"0","allow":"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture","allowfullscreen":""}}):_vm._e(),(_vm.content.videoType.value.toLowerCase() == 'vimeo')?_c('iframe',{attrs:{"title":"vimeo-player","src":("https://player.vimeo.com/video/" + (_vm.parseVimeoLink(
            _vm.content.videoUrl
          ))),"frameborder":"0","allowfullscreen":""}}):_vm._e()]):_vm._e(),_c('div',{staticClass:"modal__content__actions"},[(_vm.content.attachedFileLink)?_c('a',{attrs:{"href":_vm.content.attachedFileLink,"download":""}},[_vm._v(" "+_vm._s(_vm.$t.uiDownloadFile)+" "),_c('img',{attrs:{"src":"/assets/img/icons/icon-navigation.svg","alt":"arrow icon"}})]):_vm._e(),(_vm.content.externalLink)?_c('a',{attrs:{"href":_vm.content.externalLink,"rel":"noopener noreferrer","target":"_blank"}},[_vm._v(_vm._s(_vm.content.externalLinkTitle ? _vm.content.externalLinkTitle : "Open external link")+" "),_c('img',{attrs:{"src":"/assets/img/icons/icon-navigation.svg","alt":"arrow icon"}})]):_vm._e()])],1)]),(_vm.content.images)?_c('Tinybox',{attrs:{"images":_vm.content.images,"no-thumbs":"","loop":""},model:{value:(_vm.tinyIndex),callback:function ($$v) {_vm.tinyIndex=$$v},expression:"tinyIndex"}}):_vm._e(),_c('div',{staticClass:"modal__outer",on:{"click":function($event){return _vm.$emit('close')}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }